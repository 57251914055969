import axios from 'axios';
import type { PrescriberSchema } from '../commons/schemas';
import { client, getBaseURL } from '~/api/backendClient';
import type {
  IClinicResponse,
  IPrescriberResponse,
  IReportDataResponse,
  SuccessResponse,
} from '~/types';

export async function createPrescriberAccount(
  prescriberData: PrescriberSchema.CreatePrescriber,
) {
  const baseURL = getBaseURL();
  const requestURL = `${baseURL}/account/signup/prescriber`;

  const { data } = await axios
    .post<SuccessResponse>(requestURL, prescriberData)
    .then(res => res.data);

  return data;
}

export async function uploadPrescriberDocToS3(uploadURL: string, doc: File) {
  await axios.put(uploadURL, doc, {
    headers: {

      'Content-Type': 'multipart/form-data',
    },
  });
}

export async function savePrescriberDocument(url: string, type: string) {
  const baseURL = getBaseURL();
  const requestURL = `${baseURL}/account/document`;

  const { data } = await axios
    .post<SuccessResponse>(requestURL, {
      presigned_upload_url: url,
      document_type: type,
    })
    .then(res => res.data);
  return data;
}

export async function loadPrescriberDetails() {
  const { result } = await client.get<IPrescriberResponse>('/account');
  return result;
}

export async function acceptTermsConditions(): Promise<void> {
  await client.patch<IPrescriberResponse>('/account', {
    accepted_terms_conditions: new Date().toISOString(),
  });
}

export async function loadClinicDetails() {
  const { result } = await client.get<IClinicResponse>('/account/clinic');
  return result;
}

export async function checkClinicOnboardingCode(code: string) {
  const baseURL = getBaseURL();
  const requestURL = `${baseURL}/signup/clinic_verification`;
  const { data } = await axios
    .post<SuccessResponse>(requestURL, {
      prescriber_onboarding_code: code,
    })
    .then(res => res.data);
  return data;
}

export async function saveClinikoApiKey(apiKey: string) {
  await client.patch<SuccessResponse>('/account/cliniko', {
    cliniko_api_key: apiKey,
  });
}

/**
 * Generate a new API key for the prescriber tor the use
 * of our Healistic prescriber API
 */
export async function generateApiKey() {
  const response = await client.patch<SuccessResponse>('/account/api-key', {});
  return response.data;
}

export async function loadReport(year: number, month: number, options: string) {
  const response = await client.get<IReportDataResponse>(
    `/reports?year=${year}&month=${month}&options=${options}`,
  );
  return response.data.report;
}
